import React from "react";
import css from "../../styles/Tutorials.module.css"



const TutorialsContent = () => {
  

  return (
    <div id={css.comingSoon} className="text-border right-margin-desktop image-container">
      
        COMING SOON
    
    </div>
  );
};

export default TutorialsContent;
